/* navbar styles on scroll*/

var $window = $(window);
$body = $(document.body);
var mobileNavBreakpointX = 992;
var mobileSmallBreakpoint = 768;

var topNavHeight = 55;
var homepageNavHeight = 147;
var micrositeNavHeight = 77;

var touchEnabled = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;

function getNavSize() {
    var heroTopMargin = (navbarFixedHeight = 55);
    var subnavHeight = $('#subnav').length ? $('#subnav').height() : 0;

    var navbar = $('.navbar').height();
    var navbar_fixed = $('.navbar-fixed-top').height();
    var heroMargin = navbar > navbar_fixed ? navbar : navbar_fixed;
    var navbarHeight = $('.navbar-default-microsite').length ? 50 : 147;

    if ($('body').hasClass('navbar-min') && getViewportWidth() > mobileNavBreakpointX) {
        $('body').css('padding-top', parseInt(navbarHeight) + parseInt(subnavHeight));
    } else if ($('body').hasClass('navbar-min') && getViewportWidth() < mobileNavBreakpointX)
        $('body').css('padding-top', parseInt(heroTopMargin));
    else {
        $('body').css('padding-top', 0);
    }
}

// var ticking = false;
// window.addEventListener('scroll', function(e) {
//
//   if($('body').hasClass('navbar-min')) {
//       return;
//   }
//
//   if (!ticking) {
//     window.requestAnimationFrame(function() {
//
//       var bigLogo = $('.sitelogo');
//       var range = 60;
//       var scrollTop = $window.scrollTop();
//       var offset = bigLogo.offset().top;
//       var height = bigLogo.outerHeight();
//       offset = offset + height / 2;
//       var calc = 1 - (scrollTop - offset + range) / range;
//
//       if(scrollTop > height) {
//         calc = 1;
//       }
//
//
//       if(!$('.navbar-default-microsite').length && getViewportWidth() > mobileNavBreakpointX) {
//         if ( calc > '1') {
//           bigLogo.css({ 'opacity': 1 });
//         } else if ( calc < '0' && scrollTop > 0) {
//           bigLogo.css({ 'opacity': 0 });
//         } else {
//           bigLogo.css({ 'opacity': calc });
//         }
//       }
//
//       ticking = false;
//     });
//   }
//   ticking = true;
// });

function updateNavbar() {
    setDefaultClick();
    // getNavSize();

    var viewportWidth = getViewportWidth(),
        teaserFadeSpeed = 250,
        $teaserTiles = $('a.teaser-tile'),
        $listLanguage = $('header .usernavbar .list-language'),
        $footer = $('main + footer');

    // if(typeof $languageLinks == 'undefined' || !$languageLinks.length) {
    //     $languageLinks = $('header .usernavbar .list-language li').clone();
    // }

    if (touchEnabled) {
        $teaserTiles.off().on('click.ll', function (e) {
            var $el = $(this);

            e.stopPropagation();

            if (!$el.hasClass('hover') && !$el.hasClass('nbc')) {
                e.preventDefault();
                $el.addClass('hover').not('.nbc').find('.front').stop(true, true).fadeOut(teaserFadeSpeed);
            }

            $teaserTiles
                .not($el)
                .removeClass('hover')
                .not('.nbc')
                .find('.front')
                .stop(true, true)
                .fadeIn(teaserFadeSpeed);
        });

        $defaultClick.off('click.tt').on('click.tt', function () {
            $teaserTiles.removeClass('hover').not('.nbc').find('.front').stop(true, true).fadeIn(teaserFadeSpeed);
        });
    } else {
        $teaserTiles
            .off()
            .on('mouseenter.ll', function () {
                $(this).addClass('hover').not('.nbc').find('.front').stop(true, true).fadeOut(teaserFadeSpeed);
            })
            .on('mouseleave.ll', function () {
                $(this).removeClass('hover').not('.nbc').find('.front').stop(true, true).fadeIn(teaserFadeSpeed);
            });

        $defaultClick.off('click.tt');
    }

    if (viewportWidth < mobileNavBreakpointX) {
        // $listLanguage.html($languageLinks);
        // $listLanguage.prepend($listLanguage.find('.current'));
        // $listLanguage.find('a').off('click.ll').on('click.ll', function(e) {
        // 	var $a = $(this);
        // 	e.stopPropagation();
        //
        // 	if($a.parent().hasClass('current')) {
        // 		e.preventDefault();
        // 		$a.parents('ul').toggleClass('open');
        // 	}
        // });
        // $defaultClick.off('click.ll').on('click.ll', function() {
        // 	$languageLinks.parents('ul').removeClass('open');
        // });
        /* Rebuild service navigation in main navigation */
        // if(typeof navbarUserLinks == 'undefined') {
        // 	var $userLinks = $('.container > .user-links > li, .container > .corporate-site-link > .user-links > li').clone(true).addClass('visible-phone');
        //
        //     if($userLinks.length) {
        //         navbarUserLinks = true;
        //
        //     	$.each($userLinks, function() {
        //     		var $userLink = $(this),
        //     			$dropdown = $userLink.find('ul');
        //
        //     		if($dropdown.length) {
        //     			$dropdown.removeAttr('class').wrap('<div class="dropdown-menu"></div>');
        //     			$userLink.addClass('sub dropdown');
        //     			$userLink.children('a').append('<div class="caret-new"></div>').click(function(e) {
        //     				e.preventDefault();
        //     			});
        //     		}
        //     	});
        //
        //         if($('.navbar-default-microsite').length) {
        //             var $nav;
        //             $('.navbar-header').after($nav = $('<nav role="navigation" clasS="collapse navbar-collapse"> \
        //                 <ul class="nav navbar-nav"></ul> \
        //             </nav>'));
        //         }
        //
        //         $('.navbar-default nav > ul').append($userLinks);
        //     }
        // }
    } else {
        // $listLanguage.html($languageLinks);
        // $languageLinks.find('a').off('click.ll');
        // $defaultClick.off('click.ll');
        /* ~iOS7+ - reset mobile styles*/
        // $(".navbar-fixed-top").css({
        //     background: '',
        //     height: ''
        // });
    }
}

function setDefaultClick() {
    if (typeof $defaultClick == 'undefined' || !$defaultClick.length) $defaultClick = $('header, main, footer');
}

function updateNavbarOnScroll() {
    // var heroTopMargin = topNavHeight,
    //     navbarHeight = $('.navbar-default-microsite').length ? (micrositeNavHeight - topNavHeight) : (homepageNavHeight - topNavHeight),
    //     subnavHeight = $('#subnav').height();
    // heroTopMargin = heroTopMargin + subnavHeight;
    // var scrollTop = $window.scrollTop();
    // if (scrollTop > (navbarHeight - 9) || getViewportWidth() < mobileNavBreakpointX) {
    //     $('body').addClass('navbar-min');
    // } else {
    //     $('body').removeClass('navbar-min');
    // }
    //
    // getNavSize();
}

var responsiveVars = {
    userAgent: (navigator.userAgent || navigator.vendor || window.opera).toLowerCase(),
    iOSVersion: navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/),
};

var isIPhone = responsiveVars.userAgent.indexOf('iphone') > -1,
    isIPad = responsiveVars.userAgent.indexOf('ipad') > -1,
    isIPod = responsiveVars.userAgent.indexOf('ipod') > -1,
    isIOS = isIPhone || isIPad || isIPod,
    isAndroid = responsiveVars.userAgent.indexOf('android') > -1,
    androidHeight = 0;

if (responsiveVars.iOSVersion && responsiveVars.iOSVersion.length >= 3)
    responsiveVars.iOSVersion = [
        parseInt(responsiveVars.iOSVersion[1], 10),
        parseInt(responsiveVars.iOSVersion[2], 10),
        parseInt(responsiveVars.iOSVersion[3] || 0, 10),
    ];

function getAndroidWindowHeight() {
    androidHeight = Math.max(androidHeight, window.innerHeight);
    return androidHeight;
}

function getResponsiveWindowHeight(force) {
    try {
        if (/android/i.test(responsiveVars.userAgent)) {
            if (force) return window.innerHeight;
            else return getAndroidWindowHeight();
        } else if (isIPhone || isIPod || isIPad) {
            if (responsiveVars.iOSVersion[0] >= 7) {
                return window.innerHeight;
            }

            return (
                window.screen.availHeight - ('standalone' in window.navigator && window.navigator.standalone ? 0 : 44)
            );
        }
    } catch (e) {}

    return $(window).height();
}

// $(window).on("orientationchange", function() {
//     androidHeight = 0;
//     getAndroidWindowHeight();
// });

// $window.on('load resize orientationchange', updateNavbar);
// $window.on('load resize orientationchange scroll', updateNavbarOnScroll);

function getNavbarFixedFullHeight() {
    // var heroTopMargin = navbarFixedHeight = 55,
    //     navbarHeight = $('.navbar-default-microsite').length ? 77 : 137;
    //
    // return getResponsiveWindowHeight(true) - navbarFixedHeight + 100;
}

$(function () {
    setDefaultClick();
    updateNavbar();

    // if(getViewportWidth() < mobileNavBreakpointX)
    //     $body.addClass('navbar-min');

    var navHeightTimeout,
        navAnimating = 0,
        $navbar_toggle = $('.navbar-toggle');

    // $(window).on('resize orientationchange scroll', function() {
    //     var $navbar_collapse = $('.navbar-collapse.in');
    //     if($navbar_collapse.length && !navAnimating) {
    //         $navbar_collapse.css({
    //             'height': getNavbarFixedFullHeight(),
    //             'max-height': getNavbarFixedFullHeight()
    //         });
    //
    //         window.clearTimeout(navHeightTimeout);
    //         navHeightTimeout = window.setTimeout(function() {
    //             if(!navAnimating)
    //                 $navbar_collapse.css({
    //                     'height': getNavbarFixedFullHeight(),
    //                     'max-height': getNavbarFixedFullHeight()
    //                 });
    //         }, 150);
    //     }
    //
    // });

    // $navbar_toggle.click(function(e) {
    //     window.clearTimeout(navHeightTimeout);
    //
    //     var $navbar_collapse = $('.navbar-collapse');
    //
    //     navAnimating = 1;
    //     if($navbar_toggle.hasClass('in')) {
    //         $navbar_toggle.removeClass('in');
    //
    //         // ~iOS7+
    //         $(".navbar-fixed-top").css({
    //             background: '',
    //             height: ''
    //         });
    //         getNavSize();
    //
    //         $navbar_collapse.stop(true).animate({
    //             height: 0
    //         },200, function() {
    //             $navbar_collapse.removeClass('in');
    //             getNavSize();
    //             navAnimating = 0;
    //         });
    //     }
    //     else {
    //         $navbar_toggle.addClass('in');
    //
    //         $navbar_collapse.stop(true).css({
    //             'height': 0,
    //             'max-height': getNavbarFixedFullHeight()
    //         });
    //
    //         $navbar_collapse.animate({
    //             height: getNavbarFixedFullHeight()
    //         },200, function() {
    //             $navbar_collapse.css({
    //                 'height': getNavbarFixedFullHeight(),
    //                 'max-height': getNavbarFixedFullHeight()
    //             }).addClass('in');
    //             navAnimating = 0;
    //
    //             // ~iOS7+
    //             $(".navbar-fixed-top").css({
    //                 background: '#FFF',
    //                 height: 1500
    //             });
    //         });
    //     }
    // });

    // var $dropdownItems = $('.navbar-nav li.dropdown');

    // $.each($dropdownItems, function() {
    //     $(this).children('a').click(function(e) {
    //         var $li = $(this).parent(),
    //             isOpen = $li.hasClass('open');
    //
    //         e.stopPropagation();
    //
    //         if(getViewportWidth() < mobileNavBreakpointX || (touchEnabled && !isOpen)) {
    //             e.preventDefault();
    //
    //             $li.toggleClass('open');
    //             $dropdownItems.not($li).removeClass('open');
    //             positionNavDropdownImage($li);
    //         }
    //     }).hover(function(e) {
    //             var $li = $(this).parent();
    //
    //             if(getViewportWidth() >= mobileNavBreakpointX && !touchEnabled) {
    //                 $li.addClass('open');
    //                 $dropdownItems.not($li).removeClass('open');
    //                 positionNavDropdownImage($li);
    //             }
    //         }, function() {
    //             var $li = $(this).parent();
    //
    //             if(getViewportWidth() >= mobileNavBreakpointX && !touchEnabled) {
    //                 $li.removeClass('open');
    //             }
    //         });
    // });

    // function positionNavDropdownImage($li) {
    //     if(!Modernizr.csstransforms) {
    //         var $img = $li.find('.menuimage');
    //         $img.css({marginTop: $img.height() / - 2, marginLeft: $img.outerWidth() / -2});
    //     }
    // }

    // var $userLinks = $('.user-links a.parent').click(function(e) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     var $parent = $(this).parent().toggleClass('open');
    //
    //     if(!Modernizr.csstransforms) {
    //         var $nav = $(this).next('.user-links');
    //         $nav.css({marginLeft: $nav.outerWidth() / -2});
    //     }
    // });

    $defaultClick.click(function (e) {
        // $userLinks.parent().removeClass('open');
        // if(!$(e.target).parents(".navbar-nav").length)
        //     $dropdownItems.removeClass('open');
        // if($(e.target).parents(".navbar-fixed-top").length)
        //     return;
        // if($('.navbar-collapse.in').length) {
        //     $('.navbar-collapse').slideUp(function() {
        //         $('.navbar-collapse,.navbar-toggle').removeClass('in');
        //         $(this).css('display',"");
        //     });
        // }
    });

    // Teaser tiles
    var $teaserTileImages = $('.teaser-tile picture').parent();
    if ($teaserTileImages.length) {
        function updateTeaserTiles() {
            $.each($teaserTileImages, function () {
                var $item = $(this),
                    itemWidth = $item.parent().width() + 2,
                    itemHeight = $item.parent().height() + 2,
                    $img = $item.find('picture img'),
                    originalWidth = $img.attr('width'),
                    originalHeight = $img.attr('height'),
                    ratio = originalWidth / originalHeight,
                    styles = {
                        width: originalWidth,
                        height: originalHeight,
                    },
                    horizontalAlignment = $item.attr('data-horizontal-alignment'),
                    verticalAlignment = $item.attr('data-vertical-alignment');

                // image scaling
                switch ($item.attr('data-scale')) {
                    case '100auto':
                        styles.width = itemWidth;
                        styles.height = styles.width / ratio;
                        break;

                    case 'auto100':
                        styles.height = itemHeight;
                        styles.width = styles.height * ratio;
                        break;

                    case 'contain':
                        if (styles.width > itemWidth) {
                            styles.width = itemWidth;
                            styles.height = styles.width / ratio;
                        }

                        if (styles.height > itemHeight) {
                            styles.height = itemHeight;
                            styles.width = styles.height * ratio;
                        }

                        break;

                    case '0 0':
                        styles.width = originalWidth;
                        styles.height = originalHeight;
                        break;

                    case 'cover':
                    default:
                        styles.width = itemWidth;
                        styles.height = styles.width / ratio;

                        if (styles.height < itemHeight) {
                            styles.height = itemHeight;
                            styles.width = styles.height * ratio;
                        }
                }

                styles.width = Math.round(styles.width);
                styles.height = Math.round(styles.height);

                // horizontal alignment
                styles.position = 'absolute';
                styles.left = horizontalAlignment == 'left' ? 0 : 'auto';
                styles.right = horizontalAlignment == 'right' ? 0 : 'auto';

                if (horizontalAlignment == 'center') {
                    styles.left = '50%';
                    styles.marginLeft = styles.width / -2;
                }

                // vertical alignment
                styles.top = verticalAlignment == 'top' ? 0 : 'auto';
                styles.bottom = verticalAlignment == 'bottom' ? 0 : 'auto';

                if (verticalAlignment == 'center') {
                    styles.top = '50%';
                    styles.marginTop = styles.height / -2;
                }

                $img.css(styles)
                    .parent()
                    .siblings('.back,.front')
                    .css({
                        width: itemWidth - 2,
                        height: itemHeight - 2,
                    });
            });
        }

        $window.on('load resize orientationchange', updateTeaserTiles);
        updateTeaserTiles();
    }
    /*
	$('.ps_teaser_bg.hover').each(function() {

	    var $text_wrapper = $(this).find('.desc-wrapper');

	    if($text_wrapper.length > 0) {
	        var wrapper_height = $text_wrapper.height();
	        var $wrapper_headline = $(this).find('h2');
	        var transform_margin = Math.floor(wrapper_height / 2);

	        if($wrapper_headline.length > 0) {
	            $wrapper_headline.css({
	                'transform': 'translate3d(0,' + transform_margin + 'px,0)'
	            });

	            $(this).hover(function() {
	                $wrapper_headline.css({
	                    'transform': 'translate3d(0,0,0)'
	                });
	            }, function() {
	                $wrapper_headline.css({
	                    'transform': 'translate3d(0,' + transform_margin + 'px,0)'
	                });
	            });

	        }
	    }

	});*/
});

function getViewportWidth() {
    var a = document.documentElement['clientWidth'],
        b = window['innerWidth'];
    return a < b ? b : a;
}

function getViewportHeight() {
    var a = document.documentElement['clientHeight'],
        b = window['innerHeight'];
    return a < b ? b : a;
}

function FancySelect(options) {}

!(function ($) {
    $.extend($.fn, {
        fancySelect: function (options) {
            var $el = this;
            options = $.extend(
                {},
                {
                    emptyOption: '-',
                },
                options,
            );

            setDefaultClick();

            $.each($el, function () {
                var $lbl = $(this),
                    that = this,
                    $sSel,
                    $options,
                    $children;

                that.init = true;
                that.$lbl = $lbl;
                that.$form = $lbl.parents('form');
                that.$sel = $lbl.next();
                that.$sSel = that.$sel.clone();
                that.$span = $lbl.find('span.selected');

                $options = that.$sel.find('option');

                that.$sel.after(that.$sSel);

                that.$sSel.replaceWith(function () {
                    return $('<ul />', { html: $(this).html() })
                        .wrap('<div class="select" />')
                        .parent();
                });
                that.$sSel = that.$sel.next();

                that.$sSel.find('option').replaceWith(function (i) {
                    return $('<li />', {
                        html: i == 0 && $options[i].innerHTML == '' ? options.emptyOption : $options[i].innerHTML,
                    })
                        .addClass($options[i].className)
                        .click(function () {
                            that.$sel.val($options[i].value).change();
                        });
                });

                $.each(that.$sSel.find('optgroup'), function () {
                    var $optgroup = $(this),
                        $optgroupNew = $optgroup[0].label
                            ? $('<span>' + $optgroup[0].label + '</span>').click(function (e) {
                                  e.stopPropagation();
                              })
                            : '';
                    $optgroup.wrapInner('<li class="optgroup"><ul /></li>').children().unwrap().prepend($optgroupNew);
                });

                $lbl.click(function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    $el.not($lbl).removeClass('open');
                    $lbl.toggleClass('open');
                });

                that.$sel
                    .on('change', function () {
                        options.onChange.call(that);
                    })
                    .change()
                    .blur(function () {
                        $el.removeClass('open');
                    });
            });

            $defaultClick.click(function () {
                $el.removeClass('open');
            });
        },
    });
})(jQuery);

/* mailingwork parser */
jQuery(function ($) {
    $('.mwork table tbody tr').each(function () {
        var tmpVar = $(this);
        if (tmpVar.find('td:last-child input').length) {
            var tmpPlaceholder = tmpVar.find('td:first-child').text();
            tmpVar.find('td:last-child input').val(tmpPlaceholder);
            tmpVar.find('td:last-child input').attr('data-placeholder', tmpPlaceholder);
        } else if (tmpVar.find('td:last-child select').length) {
            var tmpPlaceholder = tmpVar.find('td:last-child select option:first-child');
            if (!tmpPlaceholder.val()) {
                tmpPlaceholder.text(tmpVar.find('td:first-child').text());
                tmpPlaceholder.attr('disabled', '1');
            }
        }
    });

    $('input[data-placeholder]').each(function () {
        if (this.value == $(this).attr('data-placeholder')) {
            $(this).addClass('placeholder');
        }
    });

    $('input[data-placeholder]')
        .focus(function () {
            if (this.value == $(this).attr('data-placeholder')) {
                this.value = '';
                $(this).removeClass('placeholder');
            }
        })
        .blur(function () {
            if (this.value == '') this.value = $(this).attr('data-placeholder');

            if (this.value == $(this).attr('data-placeholder')) $(this).addClass('placeholder');
            else $(this).removeClass('placeholder');
        });
});

function getIEversion() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    if (msie > 0) return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));

    return 1;
}
